<template>
    <section v-if="sliderLoaded" class="perks home-section mx-0" >
        <template v-for="({name, type, icon, size}, index) in perks">
            <div v-if="type == 'perk'" class="perk-container d-flex align-items-center" :class="`perk-${index}`" :key="index">
                <component :is="icon" :size="size" class="flex-shrink-0" />
                <div>
                    <h4 class="mb-0 mb-md-1">{{$t(`home.perks.${name}`)}}</h4>
                    <p :class="{small: index === 6 }">{{$t(`home.perks.${name}_description`)}}</p>
                </div>
            </div>
            <div v-else class="divider d-none" :class="index === 3 ? 'd-xl-block':'d-md-block'" :key="`divider-${index}`">
                <div />
            </div>
        </template>
    </section>
    <section v-else class="perks home-section mx-0">
        <template v-for="(item, index) of 7">
            <div v-if="index % 2 === 0" class="perk-container skeleton d-flex align-items-center" :key="index">
                <div class="mx-auto mb-3" style="width: 60px; height: 60px; border-radius: 3.12rem; background-color: var(--light-gray-501); flex-shrink: 0;" />
                <div style="width: 70%;">
                    <div class="ms-0 mb-2" style="width: 100%; border-radius: 4px; height: 20px; background-color: var(--light-gray-501);"></div>
                    <div class="ms-0 mb-0" style="width: 100%; border-radius: 4px; height: 45px; background-color: var(--light-gray-501);"></div>
                </div>
            </div>
            <div v-else class="divider d-none" :class="index === 3 ? 'd-xl-block':'d-md-block'" :key="`divider-${index}`">
                <div style="border-right: 1px solid var(--light-gray-501)"/>
            </div>
        </template>
    </section>
</template>

<script>
export default {
    name: 'PerksContainer',
    components: {
        IconTrolley: () => import('@/modules/home/assets/svg/iconTrolley.vue'),
        IconLocation: () => import('@/common/svg/iconLocation.vue'),
        IconHeadset: () => import('@/modules/home/assets/svg/iconHeadset.vue'),
        IconWallet: () => import('@/modules/home/assets/svg/IconWallet.vue')
    },
    props: {
        sliderLoaded: {
            type: Boolean
        }
    },
    data(){
        return {
            perks: [
                {
                    type: 'perk',
                    icon: 'IconTrolley',
                    name: 'deliveries',
                    size: 50
                },
                {
                    type: 'divider',
                    name: ''
                },
                {
                    type: 'perk',
                    icon: 'IconHeadset',
                    name: '24/7',
                    size: 50
                },
                {
                    type: 'divider',
                    name: '',
                },
                {
                    type: 'perk',
                    icon: 'IconLocation',
                    name: 'track_packages',
                    size: 50
                },
                {
                    type: 'divider',
                    name: '',
                },
                {
                    type: 'perk',
                    icon: 'IconWallet',
                    name: '',
                    size: 46
                }
            ]
        }
    },
    mounted() {
        this.perks[6].name = this.viewPort() === 'xl' ? 'security_payments_xl':'security_payments' 
    },
}
</script>

<style scoped>
@keyframes slide-from-bottom {
    from {
        opacity: 0;
        transform: translateY(120px);
    }
    to {
        transform: translateY(0px);
    }
}
.perks {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-auto-rows: 120px;
    justify-content: center;
}
.perk-container {
    padding-left: 0 !important;
    animation: 800ms cubic-bezier(0.33, 1.13, 0.6, 0.99) slide-from-bottom;
    animation-fill-mode: both;
}
.perk-2 {
    animation-delay: 50ms;
}
.perk-4 {
    animation-delay: 150ms;
}
.perk-6 {
    animation-delay: 200ms;
}
.perk-container:hover h4 {
    color: var(--main-red)
}
.perk-container:hover svg {
    fill: var(--dark-main-navy) !important;
    transform: rotateY(360deg);
    transform-origin: center;
    transition: 1s ease;
}
.perk-container div {
    margin-left: 30px;
}
.perk-container svg {
    min-width: 40px;
    margin-bottom: 5px;

    fill: var(--main-red) !important;
    transition: fill 0.15s ease-in-out;
}
.perk-container h4 {
    font-size: 20px;
    font-weight: 600;
    color: #334056;
    margin-bottom: 7px;
    transition: var(--transition-1);
}
.perk-container p {
    font-size: 16px;
    color: #a5b2b7;
    margin-bottom: 0;
}
@media (max-width: 576px) {
    .perks{
        grid-auto-rows: auto;
        gap: 20px;
    }
    .perk-container h4{
        font-size: 16px;
    }
    .perk-container p{
        font-size: 14px;
    }
    .perk-container svg{
        width: 40px;
    }
    .perk-container svg{
        margin-bottom: 0px;
    }
}
@media (min-width: 768px) {
    .perks {
        grid-template-columns: 300px 80px 300px;
        grid-auto-rows: 150px;
    }
    .perk-container div {
        margin-left: 35px;
    }
    .perks .divider div {
        width: 40px;
        height: 100%;
        border-right: 1px solid #c6d4db;
    }
    .perk-container svg {
        margin-bottom: 40px;
    }
}
@media (min-width: 768px) and (max-width: 991px) { .perk-container p.small {max-width: 200px;}}
@media (min-width: 992px) {
    .perks {
        grid-template-columns: 430px 80px 430px;
    }
    .perk-container svg {
        margin-bottom: 20px;
    } 
}
@media (min-width: 1200px) {
    .perks {
        grid-template-columns: 209px 38px 250px 38px 280px 38px 262px;
    }
    .perk-container div {
        margin-left: 27px;
    }
    .perks .divider div {
        width: 19.5px;
    }
}
@media (min-width: 1400px) {
    .perks {
        grid-template-columns: 222px 70px 285px 70px 290px 70px 286px;
    }
    .perk-container div {
        margin-left: 28px;
    }
    .perks .divider div {
        width: 35px;
    }
    .perk-container p.small {
        max-width: 200px;
    }
    .container {
        max-width: 1320px !important;
    }
}
</style>